// $colors: (
//   primary: #f76321,
// );
$primary: #4b5980;
$dark: #1e1e1b;
// $theme-colors: (
//   "primary": $primary,
//   "secondary": $secondary,
//   "success": $success,
//   "info": $info,
//   "warning": $warning,
//   "danger": $danger,
//   "light": $light,
//   "dark": $dark,
// );
@import "~bootstrap/scss/bootstrap";
@import "~@fortawesome/fontawesome-free/css/all";

#app {
  @extend .d-flex, .flex-column, .h-100;

  .navbar-brand {
    img {
      width: 193px;
    }
  }
  .navbar-primary {
    @extend .bg-primary;
    .navbar-nav {
      .nav-link {
        @extend .text-white;
      }
    }
  }

  img.groupe-propress {
    max-width: 20%;
  }
  img.navbar-brand {
    width: 193px;
  }

  main {
    margin-top: 80px;
  }
}
#modal-account-confirmation {
  @extend .modal-fullscreen;
}
.app-logo {
  @extend .img-fluid;
  max-width: 300px;
}
.category {
  margin-top: $spacer * 0.5;
}
.issue {
  color: $dark !important;
  //margin: $spacer*0.3;
  padding: $spacer * 0.3;
  border: $border-width $black solid;
  cursor: pointer;

  &:hover {
    transform: translateY(-$spacer * 0.3);
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
  }

  a {
    font-size: 0.8125rem;
    line-height: 1.5rem;
    letter-spacing: 0;
    display: block;
    text-decoration: none;
    color: $dark !important;
  }
  p {
    margin-bottom: 0;
  }
  img {
    // box-shadow: 0 3px 3px rgba(0, 0, 0, 0.16), 0 3px 3px rgba(0, 0, 0, 0.23);
    margin-bottom: 5px;
  }
  .info {
    font-size: 0.875rem;
    line-height: 1.375rem;
    letter-spacing: -0.025rem;
  }
  .title {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 100%;
    font-size: $font-size-base;
    font-weight: $font-weight-bold;
  }
  .published_at {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 100%;
    font-size: $font-size-base * 0.8;
    line-height: $line-height-base * 0.8;
  }
}
.header-categories {
  flex-wrap: nowrap;
  overflow-x: auto;
  display: flex;
  list-style: none;
  -webkit-overflow-scrolling: touch;
  -webkit-transition: width 0.5s ease-out;
  transition: width 0.5s ease-out;
  background-color: $body-bg;

  @extend .container, .py-3, .align-items-center;

  @media screen and (min-width: 1140px) {
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
  }
  -ms-overflow-style: none;
  &::-webkit-scrollbar {
    display: none;
  }
  a,
  p {
    @extend .p-2, .text-muted, .text-decoration-none;
    transition: color 0.3s, font-weight 0.3s, border-bottom 0.3s;
    // min-width: 100px;
    text-align: center;
    margin-right: 0.5rem;
    //::after{
    //    color: $secondary!important;
    //    font-weight: $font-weight-bold;
    //    border-bottom: $border-width*2 $secondary solid;
    //}
    //color: $secondary!important;
    //font-weight: $font-weight-bold;
    //border-bottom: $border-width*2 $secondary solid;

    &::after {
      content: "";
      display: block;
      width: 0;
      height: $border-width * 2;
      background: $light;
      transition: width 0.3s;
    }
    //&.active{
    //    &::after{
    //        width: 100%;
    //    }
    //}
    &.active,
    &:hover {
      &::after {
        width: 100%;
      }
      color: $light !important;
      font-weight: $font-weight-bold;
      background-color: $primary;
      border-radius: $btn-border-radius;
    }
  }
}
.random-image {
  background-image: url(https://source.unsplash.com/random);
  background-repeat: no-repeat;
  background-color: $light;
  background-size: cover;
  background-position: center;
}
.authentication {
  .paper {
    @extend .h-100,
      .d-flex,
      .flex-column,
      .justify-content-center,
      .align-items-center;
  }
  .form {
    width: 100%; // Fix IE 11 issue.
    margin-top: 1rem;
  }
}
footer {
  i,
  a {
    color: $dark;
  }
}
